import React from "react"
import { graphql, Link } from "gatsby"
import { css } from "@emotion/core"
import Layout from "../components/layout"
import Img from "gatsby-image"
import PageTitle from "../components/page-title"
import {Heading} from "../components/heading"
import DividedBlock from "../components/divided-block"
import WritingSummariesShort from "../components/writing-summaries-short"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"

const Photo = props => (
  <div css={css`
        padding-top: 30px;
        padding-bottom: 30px;
      `}>
    <Img sizes={props.img} />
  </div>
)

export default function Home({ data }) {
  return (
    <Layout>
      <SEO />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Decent Alternative Systems</title>
        <link rel="canonical" href="https://decentalternative.systems/" />
      </Helmet>
      <div>
        <PageTitle>Harmony of Blog and Code</PageTitle>

        <DividedBlock>
          <center>
            The future is built and shaped from our collective imaginations. 
            First, we imagine. Then, we build.
          </center>
        </DividedBlock>
        
        <DividedBlock>
          <Heading>
            <Link to="/blog" css={css`
              color: var(--color);
              text-decoration: none;
            `}>
              Life and Provocations
            </Link>
          </Heading>
          <WritingSummariesShort />    
        </DividedBlock>

        <Heading>
          Favorite Photos
        </Heading>
        <Photo img={data.kamehameha.childImageSharp.sizes} />
        <Photo img={data.sake.childImageSharp.sizes} />
        <Photo img={data.temple.childImageSharp.sizes} />
        <Photo img={data.tree.childImageSharp.sizes} />
        <Photo img={data.heidelberg.childImageSharp.sizes} />
      </div>
    </Layout>
  )
}

export const scalingImage = graphql`
  fragment scalingImage on File {
    childImageSharp {
      # Specify the image processing specifications right in the query.
      # Makes it trivial to update as your page's design changes.
      sizes(maxWidth: 700) {
        ...GatsbyImageSharpSizes
      }
    }
  }
`

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    kamehameha: file(relativePath: { eq: "assets/kamehameha-1.jpg" }) {
      ...scalingImage
    }
    heidelberg: file(relativePath: { eq: "assets/heidelberg-1.jpg" }) {
      ...scalingImage
    }
    sake: file(relativePath: { eq: "assets/japan-1.jpg" }) {
      ...scalingImage
    }
    temple: file(relativePath: { eq: "assets/japan-2.jpg" }) {
      ...scalingImage
    }
    tree: file(relativePath: { eq: "assets/tree-1.jpg" }) {
      ...scalingImage
    }
  }
`