import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import { css } from "@emotion/core"
import { Heading4 } from "./heading.js"

const Essay = props => (
    <div key={props.id}>
      <Link 
        to={props.slug}
        css={css`
        text-decoration: none;
        color: inherit;
      `}
      >
      <h3
        css={css`
          margin-bottom: 4px;
          color: #5465ff;
        `}
      >
        {props.title}{" "}
        <span
          css={css`
            color: var(--text-alt);
          `}
        >
          — {props.date}
        </span>
      </h3>
      <p css={css`
            color: var(--text-alt);
          `}
      >
        {props.excerpt}
      </p>
      </Link>
    </div>
  )


export default function WritingSummariesShort({ data }) {
    return (
        <StaticQuery
      query={graphql`
        query HeadingSummaryQuery {
            allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC }, limit: 5) {
                totalCount
                edges {
                  node {
                    id
                    frontmatter {
                      title
                      date(formatString: "DD MMMM, YYYY")
                      slug
                    }
                    excerpt
                  }
                }
            }
        }
      `}
      render={data => (
        <div>
            <Heading4>{data.allMarkdownRemark.totalCount} Posts</Heading4>
            {data.allMarkdownRemark.edges.map(({ node }) => (  
                <div>
                    <Essay 
                        id={node.id}   
                        slug={node.frontmatter.slug} 
                        title={node.frontmatter.title}
                        date={node.frontmatter.date}
                        excerpt={node.excerpt}
                    />
                </div>
            ))}
        </div>
      )}
    />
    )
}


